import { ethers } from "ethers";
import abi from 'contract/presale.json';

export async function ReadContract() {
  const contractAddress = '0xfC461E20af72Bc1731A9631DBb601fBFB89CD8A4';
  const provider = ethers.getDefaultProvider('https://bsc-dataseed.binance.org');

  const Contract = new ethers.Contract(
    contractAddress,
    abi,
    provider
  );

  return Contract;
}