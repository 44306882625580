import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { Col, Row } from 'antd';
import { CardStyled, Container, SubTitle, Text } from './styled';
import { ErrorHandling } from 'utils/errorHandling';
import { ReadContract } from 'utils/readContract';


export default function Info() {
  const [remainToken, setRemainToken] = useState('');
  const [tokenSold, setTokenSold] = useState('');
  const [minInvest, setminInvest] = useState('');
  const [beforeblock, setbeforeblock] = useState('');
  const [afterblock, setafterblock] = useState('');
  let lastRemainToken = ethers.utils.formatUnits('000000000', 9);
  let lastTokenSold = ethers.utils.formatUnits('000000000', 9);

  const Fetch = async () => {
    try {
      const contract = await ReadContract();

      const remaining = await contract.functions.remainingTokens();
      const sold = await contract.functions.totalDistributed();
      const min = await contract.functions.minInvestment();
      const enddate = await contract.functions.notAfterBlock();
      const startdate = await contract.functions.notBeforeBlock();
      setRemainToken(ethers.utils.formatUnits(remaining[0]._hex, 9));
      setTokenSold(ethers.utils.formatUnits(sold[0]._hex, 9));
      setminInvest(ethers.utils.formatUnits(min[0]._hex, 0));
      setbeforeblock(ethers.utils.formatUnits(enddate[0]._hex, 0));
      setafterblock(ethers.utils.formatUnits(startdate[0]._hex, 0));
    } catch (err) {
      ErrorHandling(err);
    }
  };

  const TimeConverter = (timestamp) => {
    const date = new Date(timestamp * 1000).toLocaleString("en-US", {timeZoneName: "short"});
    return date;
  } 

  function min() {
   
    var b = 0;
    var c = (Number(minInvest));
    if(Number(c) === Number(b)) {
      return "No min Investment";
    }else
    return "$"+ c;
    
  }


  useEffect(() => {
    Fetch();
  }, []);

  return (
    <Container>
      {/* <Title>Status</Title> */}
      <Row justify="space-around">
      <Col md={{ span: 10, offset: 0 }} lg={7}>
          <CardStyled>
            <SubTitle>Presale Start In</SubTitle>
            <Text>{TimeConverter(parseInt(afterblock))} </Text>
          </CardStyled>
        </Col>
        <Col md={{ span: 10, offset: 0 }} lg={7}>
          <CardStyled>
            <SubTitle>Presale End In</SubTitle>
            <Text>{TimeConverter(parseInt(beforeblock))} </Text>
          </CardStyled>
        </Col>
        <Col md={{ span: 10, offset: 0 }} lg={7}>
          <CardStyled>
            <SubTitle>Token Price</SubTitle>
            <Text>$ 0.027</Text>
          </CardStyled>
        </Col>
        <Col md={{ span: 10, offset: 0 }} lg={7}>
          <CardStyled>
            <SubTitle>Min Investment</SubTitle>
            <Text>{min()}</Text>
          </CardStyled>
        </Col>
        
        <Col md={{ span: 10, offset: 1 }} lg={{ span: 7, offset: 0 }}>
          <CardStyled>
            <SubTitle>Token Remaining</SubTitle>
            <Text>{new Intl.NumberFormat().format(Number(remainToken) + Number(tokenSold))} wKFX</Text>
          </CardStyled>
        </Col>
        <Col md={12} lg={7}>
          <CardStyled>
            <SubTitle>Token Sold</SubTitle>
            <Text>{new Intl.NumberFormat().format(Number(tokenSold) + Number(lastTokenSold))} wKFX</Text>
          </CardStyled>
        </Col>
      </Row>
    </Container>
  );
}
