const theme = {
  // Temp fonts
  fonts: {
    title: "Poppins, sans-serif",
    main: "Poppins, sans-serif"
  },
  // Colors for layout
  colors: {
    primary1: "rgb(208, 220, 232)",
    background1 : "url(https://app.titano.finance/_next/static/media/background-home.8302fe55.svg) center top no-repeat rgb(12, 14, 27)",
    accent1: "hsl(34.9,98.6%,72.9%)",
    button: "hsl(205.1,100%,36.1%)",
    background2: "hsl(232.7,27.3%,23.7%)",
  },
  // Breakpoints for responsive design
  breakpoints: {
    sm: 'screen and (max-width: 640px)',
    md: 'screen and (max-width: 768px)',
    lg: 'screen and (max-width: 1024px)',
    xl: 'screen and (max-width: 1280px)'
  },
}

export default theme;