import { Card } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1216px;
  min-height: calc(100vh - 120px);
  margin: auto;
  padding: 4rem 0;
  @media screen and (max-width: 500px) {
    padding: 20px 10px;
  }
`;
export const CardStyled = styled(Card)`
  width: 100%;
  min-width: 320px;
  border: 1px solid rgb(22, 23, 46);
  text-align: center;
  margin-top: 11rem;
  background: #000829;
  border-width: 1px 0px 0px 1px;
  border-top-style: solid;
  border-left-style: solid;
  border-top-color: rgb(22, 23, 46);
  border-left-color: rgb(22, 23, 46);
  border-image: initial;
  border-bottom-style: initial;
  border-bottom-color: initial;
  border-right-style: initial;
  border-right-color: initial;
  border-radius: 16px;
  background: linear-gradient(95.13deg, rgba(22, 24, 48, 0.25) 0%, rgba(46, 51, 92, 0.25) 100%);
  box-shadow: rgb(0 0 0) 5px 5px 7px -5px;
  padding: 10px 16px 20px;
`;
export const Background = styled.div`
  color: #fff;
  background: #1a2843;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
`;
export const Title = styled.h2`
  font-size: 16px;
  font-weight: 900;
  color: #2b3149;
  position: absolute;
`;
export const SubTitle = styled.p`
  font-size: 15px;
  font-weight: 700;
  color: #51a3d1;
  margin-bottom: 8px;
`;
export const Text = styled.p`
  font-size: 16px;
  font-weight: 700;
  color: #fff;
`;
