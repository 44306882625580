import { BreakLine, Container, Logo, Social, Wrapper } from "./styles";
import bitriel from '../../assets/logo.png';
import fb from '../../assets/fb.svg';
import linkedin from '../../assets/linkedin.svg';
import medium from '../../assets/medium.svg';
import twitter from '../../assets/twitter.svg';
import telegram from 'assets/telegram.svg';
import { BtnClaim, BtnSimple } from "pages/order/styled";

export default function Footer() {
  return (
    <Wrapper>
      <BreakLine />
      <Container>
        <Logo
          alt='bitriel'
          src={bitriel}
        />
        <Social>
          <a href='https://twitter.com/OKnoxfs' target='_blank' rel="noreferrer">
            <img 
              src={twitter}
              alt='twitter'
              width={30}
            />
          </a>
          <a href='https://t.me/knoxFSOfficial' target='_blank' rel="noreferrer">
            <img 
              src={telegram}
              alt='telegram'
              width={30}
            />
          </a>
          <a href='https://knoxfs.medium.com/' target='_blank' rel="noreferrer">
            <img 
              src={medium}
              alt='medium'
              width={30}
            />
          </a>
          <a href='https://www.linkedin.com/company/knoxfs/' target='_blank' rel="noreferrer">
            <img 
              src={linkedin}
              alt='linkedin'
              width={30}
            />
          </a>
          <BtnSimple href="https://knoxfs.com/KnoxFS_Pitch_Deck.pdf" scale="sm">Our Pitch Deck</BtnSimple>
        </Social>
      </Container>
    </Wrapper>
  )
}